// routes
import { Badge } from '@mui/material';
import { useEffect, useState } from 'react';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  pz: getIcon('mage:box-3d-plus'),
  wz: getIcon('mage:box-3d-minus'),
  warehouse: getIcon('mage:box-3d'),
  product_list: getIcon('mage:box-3d'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  search: getIcon('ic_zoom'),
  return: getIcon('solar:undo-left-round-linear'),
  bill: getIcon('solar:bill-list-linear'),
  offer: getIcon('solar:clipboard-linear'),
  settings: getIcon('ic_settings'),
};

export default function NavConfig() {
  const [ordersTotal, setOrdersTotal] = useState(0);
  const [unresolvedMessagesTotal, setUnresolvedMessagesTotal] = useState(0);

  useEffect(async () => {
    const ordersCountResponse = await axios.get(`/api/orders/count`, {
      params: { statuses: 'NEW' },
    });
    setOrdersTotal(ordersCountResponse.data);
  }, []);

  useEffect(async () => {
    const unresolvedConversationsResponse = await axios.get(`/api/conversations/unresolved`);
    setUnresolvedMessagesTotal(unresolvedConversationsResponse.data);
  }, []);

  return [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'Główne',
      items: [
        // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
        {
          title: 'Zamówienia',
          path: PATH_DASHBOARD.general.orders,
          icon: ICONS.cart,
          badge: (
            <Badge
              badgeContent={ordersTotal}
              color="info"
              size="small"
              sx={{ position: 'absolute', right: 10, top: 1 }}
            />
          ),
        },
        { title: 'Zwroty', path: PATH_DASHBOARD.general.order_returns, icon: ICONS.return },
        { title: 'Faktury sprzedażowe', path: PATH_DASHBOARD.invoice.root, icon: ICONS.bill },
        {
          title: 'Konwersacje',
          path: PATH_DASHBOARD.chat.root,
          icon: ICONS.chat,
          badge: (
            <Badge
              badgeContent={unresolvedMessagesTotal}
              color="info"
              size="small"
              sx={{ position: 'absolute', right: 10, top: 1 }}
            />
          ),
        },
        { title: 'Raporty', path: PATH_DASHBOARD.general.reports, icon: ICONS.dashboard },
        { title: 'Oferty', path: PATH_DASHBOARD.offer.root, icon: ICONS.offer },
        { title: 'Ustawienia', path: PATH_DASHBOARD.general.settings, icon: ICONS.settings },
      ],
    },

    {
      subheader: 'Magazyn',
      items: [
        { title: 'Magazyn', path: PATH_DASHBOARD.warehouse.root, icon: ICONS.warehouse },
        { title: 'Katalog produktów', path: PATH_DASHBOARD.product.root, icon: ICONS.product_list },
        { title: 'Przyjęcia zewnętrzne', path: PATH_DASHBOARD.warehouse_document.pz.root, icon: ICONS.pz },
        { title: 'Wydania zewnętrzne', path: PATH_DASHBOARD.warehouse_document.wz.root, icon: ICONS.wz },
      ],
    },

    {
      subheader: 'Raporty',
      items: [
        { title: 'Dzienny raport zamówień', path: PATH_DASHBOARD.general.order_reports_daily, icon: ICONS.analytics },
        { title: 'Raport zamówień', path: PATH_DASHBOARD.general.order_reports, icon: ICONS.analytics },
        { title: 'Raport dochodów', path: PATH_DASHBOARD.general.order_income_report, icon: ICONS.analytics },
        { title: 'Analityka', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
        {
          title: 'Analityk grup ofertowych',
          path: PATH_DASHBOARD.general.offer_group_analytics,
          icon: ICONS.analytics,
        },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'management',
    //   items: [
    //     // MANAGEMENT : USER
    //     {
    //       title: 'user',
    //       path: PATH_DASHBOARD.user.root,
    //       icon: ICONS.user,
    //       children: [
    //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
    //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
    //         { title: 'list', path: PATH_DASHBOARD.user.list },
    //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
    //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
    //         { title: 'account', path: PATH_DASHBOARD.user.account },
    //       ],
    //     },
    //
    //     // MANAGEMENT : E-COMMERCE
    //     {
    //       title: 'e-commerce',
    //       path: PATH_DASHBOARD.eCommerce.root,
    //       icon: ICONS.cart,
    //       children: [
    //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
    //         { title: 'product', path: PATH_DASHBOARD.eCommerce.product },
    //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
    //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
    //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
    //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
    //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
    //       ],
    //     },
    //
    //     // MANAGEMENT : BLOG
    //     {
    //       title: 'blog',
    //       path: PATH_DASHBOARD.blog.root,
    //       icon: ICONS.blog,
    //       children: [
    //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
    //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
    //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
    //       ],
    //     },
    //   ],
    // },

    // APP
    // ----------------------------------------------------------------------
    // {
    //   subheader: 'app',
    //   items: [
    //     {
    //       title: 'mail',
    //       path: PATH_DASHBOARD.mail.root,
    //       icon: ICONS.mail,
    //       info: (
    //         <Label variant="outlined" color="error">
    //           +32
    //         </Label>
    //       ),
    //     },
    //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
    //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
    //     {
    //       title: 'kanban',
    //       path: PATH_DASHBOARD.kanban,
    //       icon: ICONS.kanban,
    //     },
    //   ],
    // },
  ];
}
