import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { ordersToBoard } from '../../api/mocks/task';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  orders: [],
  order: null,
  board: {
    cards: {},
    columns: {},
    columnOrder: [],
  },
  sortBy: null,
  filters: {
    // TODO:
    // gender: [],
    // category: 'All',
    // colors: [],
    // priceRange: '',
    // rating: '',
  },
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
      state.board = ordersToBoard(action.payload);
      // console.log('order state - board: ', state.board);
    },

    // GET ORDER
    getOrderSuccess(state, action) {
      state.isLoading = false;
      state.order = action.payload;
    },

    //  SORT & FILTER PRODUCTS
    sortByOrders(state, action) {
      state.sortBy = action.payload;
    },

    filterOrders(state, action) {
      state.filters.inventoryType = action.payload.inventoryType;
      // TODO:
      // state.filters.category = action.payload.category;
      // state.filters.colors = action.payload.colors;
      // state.filters.priceRange = action.payload.priceRange;
      // state.filters.rating = action.payload.rating;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { sortByOrders, filterOrders } = slice.actions;

// ----------------------------------------------------------------------

export function getOrders(page, pageSize) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/orders?page=${page}&page_size=${pageSize}`);
      // console.log('order state - orders: ', response.data);
      dispatch(slice.actions.getOrdersSuccess(response.data));
      // dispatch(slice.actions.getOrderSuccess(mockedOrders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrder(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/orders/${id}`);
      dispatch(slice.actions.getOrderSuccess(response.data));
      // dispatch(slice.actions.getOrderSuccess(mockedOrders[0]));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
