import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import axios from '../utils/axios';
import { PATH_DASHBOARD } from '../routes/paths';

export function BarcodeScanner() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/dashboard/orders/') && location.pathname.endsWith('/packing')) {
      return;
    }

    let barcodeInput = '';
    let timeoutId;
    const handleBarcodeInput = (event) => {
      const { key } = event;

      clearTimeout(timeoutId);

      if (key === 'Enter') {
        if (barcodeInput.startsWith('Shift#') && barcodeInput.endsWith('Shift$')) {
          try {
            const barcode = barcodeInput
              .substring('Shift#'.length, barcodeInput.length - 'Shift$'.length)
              .replace('Shift', '');
            console.log(barcode);
            axios.get(`/api/barcodes?barcode=${encodeURIComponent(barcode)}`).then((response) => {
              console.log(response);
              if (response.data && 'order_id' in response.data && response.data.order_id) {
                const orderId = response.data.order_id;
                console.log(`${PATH_DASHBOARD.root}/orders/${orderId}/packing`);
                navigate(`${PATH_DASHBOARD.root}/orders/${orderId}/packing`);
              }
            });
          } catch (error) {
            console.error(error);
          }
        }
        // Clear the barcode input
        barcodeInput = '';
      } else {
        barcodeInput += key;

        timeoutId = setTimeout(() => {
          barcodeInput = '';
        }, 400);
      }
    };

    document.body.addEventListener('keydown', handleBarcodeInput);

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeEventListener('keydown', handleBarcodeInput);
    };
  }, [navigate, location]); // Run this effect only once

  return <></>;
}
