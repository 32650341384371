// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    product: path(ROOTS_DASHBOARD, '/products/:id'),
    orders: path(ROOTS_DASHBOARD, '/orders'),
    order: path(ROOTS_DASHBOARD, '/orders/:id'),
    order_packing_view: path(ROOTS_DASHBOARD, '/orders/:id/packing'),
    order_returns: path(ROOTS_DASHBOARD, '/order_returns'),
    order_return_create: path(ROOTS_DASHBOARD, '/order_returns/create'),
    order_return: path(ROOTS_DASHBOARD, '/order_returns/:id'),
    warehouses: path(ROOTS_DASHBOARD, '/warehouses'),
    reports: path(ROOTS_DASHBOARD, '/reports'),
    search: path(ROOTS_DASHBOARD, '/search'),
    order_reports: path(ROOTS_DASHBOARD, '/reports/orders'),
    order_reports_daily: path(ROOTS_DASHBOARD, '/reports/orders_daily'),
    order_income_report: path(ROOTS_DASHBOARD, '/reports/orders_income'),
    packing: path(ROOTS_DASHBOARD, '/packing'),
    scan: path(ROOTS_DASHBOARD, '/scan'),
    tasks: path(ROOTS_DASHBOARD, '/tasks'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    offer_group_analytics: path(ROOTS_DASHBOARD, '/offer_group_analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking'),
    settings: path(ROOTS_DASHBOARD, '/settings'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoices'),
    newInvoice: path(ROOTS_DASHBOARD, '/invoices/new'),
    editById: path(ROOTS_DASHBOARD, `/invoices/:id/edit`),
  },
  offer: {
    root: path(ROOTS_DASHBOARD, '/offers'),
    newOffer: path(ROOTS_DASHBOARD, '/offers/new'),
    editById: path(ROOTS_DASHBOARD, `/offers/:id/edit`),
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/products'),
    product_details: path(ROOTS_DASHBOARD, '/products/:id'),
    newProduct: path(ROOTS_DASHBOARD, '/products/new'),
    editById: path(ROOTS_DASHBOARD, `/products/:id/edit`),
  },
  warehouse: {
    root: path(ROOTS_DASHBOARD, '/warehouse'),
  },
  warehouse_document: {
    pz: {
      root: path(ROOTS_DASHBOARD, '/warehouse_documents/pz'),
      newDocument: path(ROOTS_DASHBOARD, '/warehouse_documents/pz/new'),
      editById: path(ROOTS_DASHBOARD, '/warehouse_documents/pz/:id/edit'),
    },
    wz: {
      root: path(ROOTS_DASHBOARD, '/warehouse_documents/wz'),
      newDocument: path(ROOTS_DASHBOARD, '/warehouse_documents/wz/new'),
      editById: path(ROOTS_DASHBOARD, '/warehouse_documents/wz/:id/edit'),
    },
  },
  contact: {
    root: path(ROOTS_DASHBOARD, '/contacts/list'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/contacts/list'),
    newContact: path(ROOTS_DASHBOARD, '/contacts/new'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
  //   postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  //   newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  // },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
