import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 500">
        <circle cx="200" cy="200" r="38" fill="#635AFF" />
        <circle cx="200" cy="300" r="38" fill="#635AFF" />
        <circle cx="300" cy="200" r="38" fill="#635AFF" />
        <circle cx="300" cy="300" r="38" fill="#635AFF" />

        <circle cx="110" cy="110" r="22" fill="#635AFF" />
        <circle cx="110" cy="200" r="22" fill="#635AFF" />
        <circle cx="110" cy="300" r="22" fill="#635AFF" />
        <circle cx="110" cy="390" r="22" fill="#635AFF" />

        <circle cx="390" cy="110" r="22" fill="#635AFF" />
        <circle cx="390" cy="200" r="22" fill="#635AFF" />
        <circle cx="390" cy="300" r="22" fill="#635AFF" />
        <circle cx="390" cy="390" r="22" fill="#635AFF" />

        <circle cx="200" cy="110" r="22" fill="#635AFF" />
        <circle cx="300" cy="110" r="22" fill="#635AFF" />
        <circle cx="200" cy="390" r="22" fill="#635AFF" />
        <circle cx="300" cy="390" r="22" fill="#635AFF" />

        <circle cx="200" cy="40" r="10" fill="#635AFF" />
        <circle cx="300" cy="40" r="10" fill="#635AFF" />

        <circle cx="200" cy="460" r="10" fill="#635AFF" />
        <circle cx="300" cy="460" r="10" fill="#635AFF" />

        <circle cx="40" cy="200" r="10" fill="#635AFF" />
        <circle cx="40" cy="300" r="10" fill="#635AFF" />

        <circle cx="460" cy="200" r="10" fill="#635AFF" />
        <circle cx="460" cy="300" r="10" fill="#635AFF" />
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
