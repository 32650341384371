// hooks
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  let { user } = useAuth();

  if (user?.email === 'ms.annahajduk@gmail.com') {
    user = { ...user, displayName: 'Anna Lisowska', role: 'admin' };
  }
  if (user?.email === 'bleare@gmail.com') {
    user = { ...user, displayName: 'Piotr Lisowski', role: 'admin' };
  }
  if (user?.email === 'leszeklisowski@gmail.com') {
    user = { ...user, displayName: 'Leszek Lisowski', role: 'user' };
  }
  if (user?.email === 'lucyliso@gmail.com') {
    user = { ...user, displayName: 'Lucyna Lisowska', role: 'user' };
  }

  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
