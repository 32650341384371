import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   ),
        // },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        // { path: 'reset-password', element: <ResetPassword /> },
        // { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'app', element: <GeneralApp /> },
        { path: 'reports', element: <GeneralEcommerce /> },
        { path: 'orders', element: <GeneralOrders /> },
        { path: 'orders/:id', element: <OrderDetails /> },
        { path: 'orders/:id/packing', element: <OrderPacking /> },
        { path: 'offers/:id', element: <OfferDetails /> },
        { path: 'order_returns', element: <GeneralOrderReturns /> },
        { path: 'order_returns/create', element: <OrderReturnCreate /> },
        { path: 'order_returns/:id', element: <OrderReturnDetails /> },
        { path: 'lugus/products', element: <LugusProducts /> },
        { path: 'reports/orders', element: <OrderReport /> },
        { path: 'reports/orders_daily', element: <OrderReportDaily /> },
        { path: 'reports/orders_income', element: <OrderIncomeReport /> },
        { path: 'warehouses', element: <GeneralWarehouses /> },
        { path: 'packing', element: <GeneralPacking /> },
        { path: 'tasks', element: <GeneralTasks /> },
        { path: 'products', element: <GeneralProducts /> },
        { path: 'products/:id', element: <ProductDetails /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'offer_group_analytics', element: <GeneralOfferGroupAnalytics /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },
        { path: 'settings', element: <GeneralSettings /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'products/:id', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'products/new', element: <EcommerceProductCreate /> },
            { path: 'products/:id/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        {
          path: 'warehouse',
          children: [{ element: <GeneralWarehouse />, index: true }],
        },
        {
          path: 'warehouse_documents/pz',
          children: [
            { element: <GeneralPZs />, index: true },
            { path: 'new', element: <PZCreate /> },
            { path: ':id', element: <PZDetails /> },
            { path: ':id/edit', element: <PZCreate /> },
          ],
        },
        {
          path: 'warehouse_documents/wz',
          children: [
            { element: <GeneralWZs />, index: true },
            { path: 'new', element: <WZCreate /> },
            { path: ':id', element: <WZDetails /> },
            { path: ':id/edit', element: <WZCreate /> },
          ],
        },
        {
          path: 'invoices',
          children: [
            { element: <GeneralInvoices />, index: true },
            { path: 'vat/new', element: <InvoiceCreate type="VAT" /> },
            { path: 'vat/:id/edit', element: <InvoiceCreate type="VAT" /> },
            { path: 'personal/new', element: <InvoiceCreate type="personal" /> },
            { path: 'personal/:id/edit', element: <InvoiceCreate type="personal" /> },
            { path: ':id', element: <InvoiceDetails /> },
          ],
        },
        {
          path: 'offers',
          children: [
            { element: <GeneralOffers />, index: true },
            { path: 'new', element: <OfferCreate /> },
            { path: ':id', element: <OfferDetails /> },
            { path: ':id/edit', element: <OfferCreate /> },
          ],
        },
        {
          path: 'contacts',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <ContactList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPosts /> },
        //     { path: 'post/:title', element: <BlogPost /> },
        //     { path: 'new-post', element: <BlogNewPost /> },
        //   ],
        // },
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
        //     { path: 'label/:customLabel', element: <Mail /> },
        //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
        //     { path: ':systemLabel', element: <Mail /> },
        //     { path: ':systemLabel/:mailId', element: <Mail /> },
        //   ],
        // },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        // { path: 'calendar', element: <Calendar /> },
        // { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <GeneralOrders />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralOfferGroupAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralOfferGroupAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const GeneralOrders = Loadable(lazy(() => import('../pages/dashboard/orders/GeneralOrders')));
const GeneralOrderReturns = Loadable(lazy(() => import('../pages/dashboard/GeneralOrderReturns')));
const OrderReturnCreate = Loadable(lazy(() => import('../pages/dashboard/OrderReturnCreate')));
const OrderReturnDetails = Loadable(lazy(() => import('../pages/dashboard/OrderReturnDetails')));
const OrderDetails = Loadable(lazy(() => import('../pages/dashboard/orders/OrderDetails')));
const OrderPacking = Loadable(lazy(() => import('../pages/dashboard/orders/OrderPacking')));
const OrderReport = Loadable(lazy(() => import('../pages/dashboard/OrderReport')));
const OrderReportDaily = Loadable(lazy(() => import('../pages/dashboard/OrderReportDaily')));
const OrderIncomeReport = Loadable(lazy(() => import('../pages/dashboard/OrderIncomeReport')));
const OfferDetails = Loadable(lazy(() => import('../pages/dashboard/offers/OfferDetails')));
const LugusProducts = Loadable(lazy(() => import('../pages/dashboard/LugusProducts')));
const GeneralWarehouse = Loadable(lazy(() => import('../pages/dashboard/GeneralWarehouse')));
const GeneralWarehouses = Loadable(lazy(() => import('../pages/dashboard/GeneralWarehouses')));
const GeneralPacking = Loadable(lazy(() => import('../pages/dashboard/GeneralPacking')));
const GeneralTasks = Loadable(lazy(() => import('../pages/dashboard/GeneralTasks')));
const GeneralProducts = Loadable(lazy(() => import('../pages/dashboard/GeneralProducts')));
const GeneralSettings = Loadable(lazy(() => import('../pages/dashboard/GeneralSettings')));
const ProductDetails = Loadable(lazy(() => import('../pages/dashboard/ProductDetails')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const ContactList = Loadable(lazy(() => import('../pages/dashboard/ContactList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/ContactCreate')));
const GeneralInvoices = Loadable(lazy(() => import('../pages/dashboard/invoices/GeneralInvoices')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/invoices/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/invoices/InvoiceCreate')));
const GeneralOffers = Loadable(lazy(() => import('../pages/dashboard/offers/GeneralOffers')));
const OfferCreate = Loadable(lazy(() => import('../pages/dashboard/offers/OfferCreate')));
const GeneralPZs = Loadable(lazy(() => import('../pages/dashboard/GeneralPZs')));
const PZDetails = Loadable(lazy(() => import('../pages/dashboard/PZDetails')));
const PZCreate = Loadable(lazy(() => import('../pages/dashboard/PZCreate')));
const GeneralWZs = Loadable(lazy(() => import('../pages/dashboard/GeneralWZs')));
const WZDetails = Loadable(lazy(() => import('../pages/dashboard/WZDetails')));
const WZCreate = Loadable(lazy(() => import('../pages/dashboard/WZCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
