import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from '../utils/axios';

const OrganizationSettingsContext = createContext();

export const OrganizationSettingsProvider = ({ children }) => {
  const [organizationSettings, setOrganizationSettings] = useState(null);

  useEffect(() => {
    // Fetch organization settings from API
    const fetchOrganizationSettings = async () => {
      try {
        const response = await axios.get('api/organizations/');
        const data = await response.data;
        setOrganizationSettings(data);
      } catch (error) {
        console.error('Error fetching organization settings:', error);
      }
    };

    fetchOrganizationSettings();
  }, []);

  return (
    <OrganizationSettingsContext.Provider value={organizationSettings}>{children}</OrganizationSettingsContext.Provider>
  );
};

export const useOrganizationSettings = () => useContext(OrganizationSettingsContext);
