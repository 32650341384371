import axios from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.request.use(
  async (config) => {
    const fullHostname = window.location.hostname.replace('www.', '');
    const parts = fullHostname.split('.');
    const subdomain = parts.length > 2 ? parts[0] : 'sprawnabryka';

    config.headers['X-Subdomain'] = subdomain;
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
